
.landing-hero-section {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	margin: 2em;
	margin-bottom: 2em;
	margin-top: 2em;	
}
.landing-hero-header {
	font-size: 55px;
	font-weight: 900;
	margin-bottom: 20px;
} 
.landing-hero-subtitle {
	font-size: 24px;
	margin-bottom: 30px;
	color: grey;
}
.main-cta-button {
	width: 250px;
	padding: 10px 30px;
	font-weight: bold;
}
.landing-hero-image {
	width: 80%;
	max-width: 1200px;
	margin-top: 40px; 
	border-radius: 10px;
/*	max-width: 1000px;	*/
}

.landing-testimonial-image {
  	border-radius: 50%;
  	width: 40px;
  	height: 40px;	
}

.landing-how-osoji-works {
	font-size: 46px;
	font-weight: 700;
	line-height: 54.56px;
/*
	padding: 2em;*/
	margin: auto;	
	margin-top: 2em;
	margin-bottom: 1em;	
}


.landing-intro-section-A {
	display: flex;
	flex-direction: row;
	margin-top: 40px;
	margin-bottom: 80px;
	padding-left: 40px;
	padding-right: 40px;
	align-items: center;
	justify-content: space-evenly;
}
.landing-intro-image {
	width: 60%;
	min-width: 300px;
	max-width: 800px;
	border-radius: 10px;
}
.landing-intro-text-group {
	text-align: left;
	width: 30%;
	max-width: 400px;
}
.landing-intro-text-group h2 {
	font-weight: 700;
	padding: 10px 10px;
}
.landing-intro-text-group h4 {
	color: var(--header);
	padding: 0px 10px;
	line-height: 150%;	
}
.landing-intro-learn-more {
	padding: 0px 10px;
}

.landing-outtro-section {
	margin: auto;
	margin-bottom: 4em;
	margin-top: 4em;	
	max-width: 650px;	
}
.landing-outtro-section h4 {
	line-height: 150%;		
}
.landing-outtro-section p {
	color: grey;
	margin-top: 20px;
}


@media only screen and (max-width: 1000px) { /*ipad regular*/
	.landing-intro-image {
		width: 55%;
	}
	.landing-intro-text-group {
		width: 40%;
	}
}

@media only screen and (max-width: 767px) { /*mobile*/
	.landing-hero-section {
		margin: 1em;
	}
	.landing-hero-header {
		font-size: 36px;
		width: 80%;
		margin: auto;
		margin-bottom: 20px;
		font-weight: bold;
	}	
	.landing-hero-subtitle {
		font-size: 18px;
		max-width: 80%;
		margin: auto;
		margin-bottom: 20px;
	}	
	.main-cta-button {
		font-weight: normal;
	}
	.landing-hero-image {
		width: 100%;
	}

	.landing-how-osoji-works {
		display: none;
	}

	.landing-intro-section-A {
		flex-direction: column-reverse;

		margin-top: 40px;
		margin-bottom: 40px;
		padding-left: 0px;
		padding-right: 0px;
		align-items: center;
		justify-content: space-evenly;

	}
	.landing-intro-section-B {
		flex-direction: column;
	}	
	.landing-intro-image {
		width: 100%;
		min-width: 300px;
		margin-top: 10px;		
	}	
	.landing-intro-text-group {
		width: 80%;
/*		padding: 0px 26px;*/
	}
	.landing-intro-text-group h2 {
/*		margin: 0px 20px;	*/	
	}
	.landing-intro-text-group h4 {
		font-size: 1.3em;
	}	

	.landing-outtro-section {
		width: 80%;
		max-width: 600px;
	/*	margin: auto;*/
	}
	.landing-outtro-section h4 {
		font-size: 1.3em;
		line-height: 150%;		
	}
}