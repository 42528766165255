body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;   
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary: #FF6700; 
  --main: #FFC107;
  --text: #FBA500; 
  --texthover: #DC7E2B;
  --secondary: #2176FF;
  --bg: rgb(250, 249, 249); 
  --bg2: #FFFBEF;   /* postlistng-related background  */
  --bg3: #FFFFEC;
  --greyborder: #ced4da;
  --hover-bg: #eee;

  --header: black;  /* black/white text */
  --general-bg: white;   /* non-postlistng background */
  /*form input*/
  --form-bg: #F9F9F9;  
  --form-text: black;  
  --search-border: #ced4da;  
  /*NavBar & Search */   
  --navbar-bg: white;
  --navbar-fixed-bg: rgba(255,255,255,0.9);    
  --navbar-dropdown-bg: white;  
  --navbar-quicklink: rgba(0,0,0,.5);
  --navbar-quicklink-hover: black;
  /*skeleton*/
  --skeleton-bg: #eee;
  --skeleton-highlight: #f5f5f5;
  /*posts*/
  --linkpost-bg: white;
  --linkpost-desc: #6C757D;   /* grey-ish in light mode for date, etc*/
  --notepost-bg: #FFF7CD;
  --notepost-desc: black;
  --notepost-bottom: transparent;
  --notepost-fold: #FFE977;
  --post-border: #E1E3E6;
  --notepost-border: transparent;
  --emailpost-red: rgb(214, 70, 79);
  --emailpost-white: rgb(241, 241, 241);
  --emailpost-blue: rgb(56, 143, 226);



/*  --notepost-blur-long: linear-gradient(0deg, rgba(255, 247, 205, 1) 0%, rgba(0, 0, 0, 0) 100%);*/
  --notepost-blur-long: linear-gradient(0deg, rgba(255, 247, 205, 1) 0%,  rgba(255, 255, 255, 0)100%);
  --notepost-blur-long-space-below: rgb(255, 247, 205);
  /*warroom section*/
  --room-tile: #efefef;
  --room-card-border: #ced4da;
  --create-room-card-bg: white;
  /*profile*/
  --setting-form-bg: white;
  --setting-form-text: black;    
  /*modal*/
  --modal-bg: white;   
  /*landing*/  
  --usecase-card-bg: white;
  --usecase-section-bg: #FFFBEF;  
  --testimonial-card-bg: #FBFBFB; 
  --solutions-text: black;
  --footer-landing-bg: rgb(250, 249, 249);  
  --select-button: rgb(224, 217, 215);
  --select-button-hover: rgb(189, 183, 181);
}

body.dark {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;   
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #292929;

  --primary: #FF6700; 
  --main: #FFC107;
  --text: #FBA500; 
  --texthover: #DC7E2B;
  --secondary: #2176FF;
  --bg: #292929; /* pages */
  --bg2: #292929; /*use in home/warroom in post listing section*/
  --bg3: #202124; /*in gradient for postdetailsUI only*/
  --greyborder: #ced4da;
  --hover-bg: grey;  
  /*
    black 1:  202124  (strongest)
    black 2:  292929
    black 3:  363739
  */
  --header: white;   /* black/white text */
  --general-bg: #202124;   /* non-postlistng background */
  /*form input*/
  --form-bg: #363739;  
  --form-text: #F9F9F9;
  --search-border: grey;
  /*NavBar & Search */  
  --navbar-bg: #292929;  
  --navbar-fixed-bg: rgba(41,41,41,0.9);  
  --navbar-dropdown-bg: #202124;  
  --navbar-quicklink: rgba(255,255,255,.5);  
  --navbar-quicklink-hover: white;  
  /*skeleton*/
  --skeleton-bg: #202124;
  --skeleton-highlight: #444;  
  /*posts*/
  --linkpost-bg: #202124;
  --linkpost-desc: #dfdfe5; 
  --notepost-bg: #202124;  
  --notepost-desc: white;
  --notepost-bottom: #FBA500;
  --notepost-fold: #FBA500;
  --notepost-border: grey;
  --post-border: grey;
  --emailpost-red: rgb(214, 70, 79, 0.8);
  --emailpost-white: rgb(241, 241, 241, 0.8);
  --emailpost-blue: rgb(56, 143, 226, 0.8);
  --notepost-blur-long: linear-gradient(0deg, rgb(10, 11, 15) 0%, rgba(0, 0, 0, 0) 100%);
  --notepost-blur-long-space-below: rgb(10, 11, 15);
  /*warroom section*/
  --room-tile: grey;
  --room-card-border: transparent;
  --create-room-card-bg: #363739;
  /*profile*/
  --setting-form-bg: #363739;
  --setting-form-text: white;  
  /*modal*/
  --modal-bg: #292929;  
  /*landing*/  
  --usecase-section-bg: #202124;
  --usecase-card-bg: #363739;
  --testimonial-card-bg: #202124;   
  --solutions-text: grey;
  --footer-landing-bg: black;
  /*pin and select icon bg*/
  --select-button: rgba(105,105,105,1);  
  --select-button-hover: rgb(189, 183, 181);  
}

.modal-general { /*for all the modals to handle Dark Mode*/
  background-color: var(--modal-bg);
  color: var(--header);
}
.modal-general .close { /*modal close for dark screen*/
  color: var(--header);
}

.modal-not-darkmode { /* handle email full screen */
  background-color: white;
  color: black !important;  
}
.modal-not-darkmode p {
  color: black !important;  
}
/*to fix Reactstrap modal bug,  Roboto   */  
.modal-open {
    padding-right: 0px !important;
}

html {
  overflow-x: hidden;
}

h1,h2,h4 { /*New May 2021: so that rich text margin is consistent with p */  
  margin-bottom: 16px;
}

p,h1,h2,h3,h4,h5,h6 { /*for dark mode*/
  color: var(--header);
}

code {
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #242322;
  border-radius: 2px;
  background: #ebebeb;
  padding: 3px 5px;  */
  font-family: inherit;
  font-size: inherit;
  color: #212529;
  background: #ffff00;
  padding: 1px 0px;
}

ol {
  padding-inline-start: 1em;
}
ul {
  padding-inline-start: 1em;
}

a {
  color: var(--text); 
}

a:hover {
  color: var(--texthover); 
}
  
.header {
  font-weight: 500;
  font-size: 40px;
  line-height: 54.56px;
  margin-top: 16px;
  margin-bottom: 4px;
  color: var(--header);
}

.main-button {
    background-color: var(--main);
    font-weight: 400;
    padding: .375rem .75rem;
    border-radius: .25rem;
    border: 1px solid transparent;
    vertical-align: middle;
    line-height: 1.5;
    display: inline-block;
}
.main-button:hover {
    background-color: #f0b607;
    border: 1px solid var(--main);
    color: black;
}
.main-button:focus {
    box-shadow: 0px 0px 0px 1px var(--primary) inset;
    outline: none;    
}

.input-field {
    padding: 5px 10px 5px 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
}
.input-field:hover {
    border: solid var(--main) 1px;
}
.input-field:hover::placeholder{
    color: #8e8e8ede;
}

.input-field:focus {
    border-color: var(--main);
    box-shadow: 0 0 2px 0 #ff670090;
}


.dont-link-me {
  text-decoration: none;
  color: inherit;
}
.dont-link-me:hover {
  text-decoration: none;
  color: inherit;
}

/* for unstyled block in Editor*/
.editor-default-unstyled-block {
  margin-bottom: 16px;
}




@media only screen and (max-width: 600px) {
   .header {
      font-weight: 500;
      font-size: 30px;
      line-height: 34.56px;
      margin-top: 10px;
      margin-bottom: 4px;
  } 
}

