.footer-landing {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
	align-items: center;
	width: 100%;
	background-color: var(--footer-landing-bg);	
/*	background-color: var(--bg);*/
	padding-top: 40px;
/*	padding-bottom: 40px;*/
}
.footer-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 50%;
	margin-top: 0px;
	margin-bottom: 20px;
}

.footer-list-group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;	
}

.footer-container h4 {
	font-weight: bold;
	margin-bottom: 20px;
}
.footer-container ul {
	padding: 0px;
}
.footer-container li {
	list-style-type: none;
	text-align: left;
	margin-bottom: 10px;	
	/*color: var(--header);*/
	color: grey;
}

.footer-copyright {
/*	color: var(--header);*/
	color: grey;
	margin-top: 15px;
	font-size: 0.9em;
}

/* ************* LOWER PART ************* */
/*
.footer-master {
	background-color: var(--footer-landing-bg);		
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 15px;
	padding-left: 40px;
	padding-right: 40px;
	width: 100%;
}

.footer-text-group {
	display: flex;
	flex-direction: row;
	width: 400px;
	justify-content: space-between;
}

.footer-text-group p {
	color: var(--header);
}


.social-icon {
	margin-right: 10px;
	margin-left: 10px;
}

.footer-flag {
	height: 20px;
	margin-left: 5px;
}*/



@media only screen and (max-width: 1068px) {
	.footer-container {
		width: 70%;
	}
}

@media only screen and (max-width: 768px) {
	.footer-container {
		flex-direction: column;
		align-items: center;
	}
	.footer-list-group {
		width: 320px;
		margin-top: 40px;
		margin-bottom: 20px;
	}
	.footer-copyright {
		text-align: center;
	}

/*	.footer-master {
		padding-left: 15px;
		padding-right: 15px;
	}
	.footer-text-group {
		flex-direction: column;
		text-align: left;
	}
	.footer-icon-group {
		display: flex;
		flex-direction: row;
	}*/


}

