
.testimonial-section-container {
	margin-top: 40px;
	margin-bottom: 40px;
}

.testimonial-container {
	padding: 1em, 2em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;	
}


.testimonial-card {
	background-color: var(--testimonial-card-bg);
	color: var(--header);
	border: 1px solid rgba(128,128,128, 0.5);
	border-radius: 10px;
	box-shadow:  3px 3px 1px rgba(0, 0, 0, 0.1);	
	width: 280px;
	margin: 20px;
	padding: 20px;
}
.testimonial-card-top {
	text-align: left;
	margin-bottom: 10px;
}
.testimonial-card-middle {
	text-align: left;
	height: 250px;
	padding: 10px;
}
.testimonial-card-bottom {
	display: flex;
	flex-direction: row;
	text-align: left;
}
.testimonial-card-bottom-right {
	margin-left: 10px;
	width: 60%;
}
.testimonial-image {
  	border-radius: 50%;
  	width: 50px;
  	height: 50px;
}
.testimonial-name {
	font-weight: bold;
	margin-bottom: 0px;
	margin-top: 5px;
	font-size: 1.1em;
}
.testimonial-title {
	color: grey;
	font-size: 0.8em;
}

@media only screen and (max-width: 1068px) {
	.testimonial-container {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;	
		padding: 1em;	
	}
	.testimonial-card {
		margin: 8px;
		width: 240px;
		padding: 5px;
	}
	.testimonial-card-middle {
		font-size: 0.9em;
		height: 200px;
	}
	.testimonial-card-bottom {
		padding-left: 10px;
	}		
}

@media only screen and (max-width: 767px) {
	.testimonial-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}	
	.testimonial-card {
		width: 70%;
		margin: 10px;
		padding: 10px;
	}	
	.testimonial-card-middle {
		font-size: 0.9em;
		height: auto;
	}	
	.testimonial-card-bottom {
/*		padding-left: 10px;*/
	}			
}