.new-alert {
	position: fixed;
	top: 10px;
	left: 50%;
	width: 400px;
	margin-left: -200px; /*this needs to be half the width*/
	z-index: 10000;
}

@media only screen and (max-width: 750px) {
	.new-alert {
		width: 300px;
		margin-left: -150px;		
	}	
}