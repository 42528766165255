.search-form {
    margin-right: 8px; 
    margin-left: 16px;
    margin-top: -4px;
    position: relative;
    display: inline-block; 
}
 
.search-box {
    min-width: 150px;
    width: 300px; 
    padding: 5px 5px 5px 40px;
    border: 1px solid var(--search-border);
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    font-weight: 200;
    justify-content: center;
    height: 40px;
    vertical-align: -50%;
    background-color: var(--form-bg);    
    caret-color: var(--main);
    color: var(--navbar-quicklink);

}
.search-box:hover {
    border: solid var(--main) 1px;
}
.search-box::placeholder {
    color: var(--search-border);
}
.search-box:hover::placeholder{
    color: #8e8e8ede;
}
.search-box:focus {
    border-color: var(--main);
    box-shadow: 0 0 2px 0 #ff670090;
}
.search-box::-webkit-search-cancel-button{
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    cursor: pointer;
    background-image: url('./x-letter.svg');
    margin-right: 5px;
}


@media only screen and (max-width: 1000px) {
    .search-box {
        width: 200px;
    }
}

/*@media only screen and (max-width: 770px) {
    .search-form {
        margin-right: 0px;
    }
    .search-box {
        width: 200px;    
    }
}*/
@media only screen and (max-width: 767px) {
    .search-form {
        margin-right: 0px;
        height: 45px;
        width: 48vw;
    }
    .search-box {
        height: 34px;
        width: 100%;  
        padding: 0px 5px 0px 35px;
        min-width: 0px;  
    }
}

@media only screen and (max-width: 350px) {  /*for iPhone SE*/
    .search-form {
        width: 43vw;
    }
}


