.navbar-container {
	position: sticky;
   top: 0;
	z-index: 3;   
	width: 100%; 
	background-color: var(--navbar-bg);
}

.dropdown-item:focus {  /*to override default bootstrap blue*/
	background-color: var(--main) !important; 
	color: black!important; 
	outline: none !important;
}

.dropdown-menu-icon {
	width: 18px;
	margin-bottom: 2px; 
	margin-right: 5px;	
}

.nav-quick-links {
	padding-top: 8px;
	color: var(--navbar-quicklink);
	margin-left: 10px;
	margin-right: 10px;
}
.nav-rooms-menu-header {
	color: var(--navbar-quicklink) !important;
}
.nav-quick-links:hover, .nav-rooms-menu-header:hover {
	color: var(--navbar-quicklink-hover) !important;
}

.navbar.transparent.navbar-inverse .navbar-inner {
   background: rgba(0,0,0,0.4);
}

.navbar-right {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
/*	width: 150px;*/
/*	background-color: yellow;*/
}

.navbar-dropdown-menu {
	background-color: var(--navbar-dropdown-bg);
	color: var(--navbar-quicklink-hover);
}
.navbar-dropdown-item {
	background-color: var(--navbar-dropdown-bg);
	color: var(--navbar-quicklink-hover);	
}

.menu-create-room {
	margin-left: 25px;
	width: 230px;
}
.menu-create-room-input {
	margin-left: 25px;
	margin-top: 5px;
}
.menu-create-room-input input {
	width: 80%;
	padding: 3px 10px;
	background-color: var(--form-bg);
	color: var(--form-text);
	border: 1px solid var(--greyborder);	
}
.menu-create-room-input input:focus {
	border: 1px solid var(--main);		
	outline: none;
}


.navbar-right-action-login {
	margin-bottom: 3px;
	margin-right: 16px;
	font-size: 0.9em;
	color: var(--header) !important;
}

.navbar-right-action-signup {
	margin-bottom: 3px;
	margin-right: 10px;
	font-size: 0.9em;
}

.navbar-search {
/*	background-color: forestgreen;
	justify-content: center;*/
}
.navbar-search-mobile {
	background-color: red;
	display: none;
	width: 20px
}

.news-red-dot {
    width: 7px;
    height: 7px;
    margin-bottom: 8px;
    margin-left: 3px;
}

.hide-in-desktop {
	display: none;
}

.menu-shared-by-others {
/*	text-align: center;*/
	margin-left: 10px;
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: 0.8em;
	font-style: italic;
}

/*.fixed-navbar-with-selected-posts-container {
	position: fixed;
   top: 0;
	z-index: 99999;   
	width: 100%; 
	background-color: var(--navbar-fixed-bg);	
	color: var(--header);
	height: 60px;
	align-items: center;
	padding: 15px;
	font-size: 20px;

}*/


@media only screen and (max-width: 1025px) { /*ipad pro and below ONLY */
	.hide-in-desktop {
		display: block;
	}
}

@media only screen and (max-width: 945px) {  /*ipad normal and below ONLY */
	.hide-in-ipad {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.nav-quick-links {
		padding-top: 8px;
		margin-left: 0px;
		margin-right: 10px;
	}
	.nav-link-padding {
		padding: 8px;
	}
	.navbar-right {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
	}
	.navbar-right-action-login {
		margin-bottom: 15px;
	}

	.dropdown-menu {
		text-align: center;
		margin: auto;
		border: none;
	}
	.menu-create-room {
		display: none;
	}
	.menu-create-room-input {
		display: none;
	}
	.warroom-menu-divider {
		display: none;
	}

	.hide-in-ipad {
		display: none;
	}

/*	.menu-shared-by-others {

		margin-top: 20px;
		text-decoration: underline;
	}	*/
}

