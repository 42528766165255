
.hero-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
/*	background-color: var(--bg2);*/
	margin: 2em;
	margin-bottom: 0em;
	margin-top: 2em;
/*	height: 30vh;*/
/*	height: 50%;	*/	
}
.hero-header {
	font-size: 55px;
	font-weight: 900;
	margin-bottom: 10px;
} 
.hero-subtitle {
	font-size: 24px;
	margin-bottom: 20px;
	color: grey;
}
.hero-subtitle-2 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27.28px;
	max-width: 280px;
}


.hero-bottom {
	display: flex;
	flex-direction: row;
	width: 90%;
	max-width: 1200px;
}
.hero-image {
	width: 78%;
	max-width: 800px;
	margin-top: 20px; /*for YINGDA image only */
/*	max-width: 1000px;	*/
}
.hero-image-2 {
	width: 78%;
	max-width: 800px;  
/*	box-shadow: inset 0 -2px 6px 0 rgb(255 193 7 / 30%), 0 25px 50px -10px rgb(255 193 7 / 20%), 0 8px 15px -8px rgb(255 193 7 / 25%);*/
	box-shadow: inset 0 -2px 6px 0 rgb(11 38 65 / 30%), 0 25px 50px -10px rgb(50 50 93 / 20%), 0 8px 15px -8px rgb(0 0 0 / 25%);
	border-radius: 16px;
/*	padding: 1rem;*/
/*	border: 16px rgba(255,255,255,1) solid;*/
	margin-top: 40px;
}
.hero-what-is-osoji {
	display: flex;
	flex-direction: column;
	justify-content: center;
/*	font-size: 1em;*/
/*	margin-top: 20px;*/
	text-align: left;
	width: 300px;
	vertical-align: center;
	margin: auto;
/*	color: grey;*/
}
.hero-what-is-osoji ul {
	padding-left: 0px;
/*	margin: auto;*/
	margin-bottom: 20px;
}
.hero-what-is-osoji ul li {
	list-style: none;
	padding-left: 0px;
	color: grey;
/*	font-style: italic;*/
	font-size: 18px;
	line-height: 27.28px;
}



.how-it-works-container {
	padding: 2em;
	width: 80%;
	margin: auto;
}
/*.how-it-works-container p {
	width: 100%;
}*/
.landing-section-header {
	font-size: 46px;
	font-weight: 700;
	line-height: 54.56px;
}

.landing-section-subtitle {
	margin: 0;
	color: grey;
}



.how-it-works-bottom{
	display: flex;
	flex-direction: row;
	justify-content: center;

}
.how-it-works-group {
	width: 400px;
	margin-left: 10px;
	margin-right: 10px;
/*	background-color: yellow;*/
}
.how-it-works-image {
	max-width: 250px;
	height: 200px;
}
.how-it-works-text {
	margin-top: 10px;
	font-size: 1.2em;
	padding: 10px;
/*	width: 80%;*/
}



.benefit-container-1 {
	height: 85vh;
	width: 100%;
	background-image: url("https://simplenoteblog.files.wordpress.com/2020/07/img_simplenote_hero.png");
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;	
}

.benefit-container-2 {
	padding: 2em;
/*	background-color: #F0F8FF;*/
}
.benefit-container-overlay-2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 80%;
	margin: auto;
}
.benefit-image-2 {
	width: 50%;
}
.benefit-image-2-mobile {
	display: none;
}
.benefit-text-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
/*	margin: auto;*/
}
.benefit-text-2 h2 {
	margin-bottom: 30px;
}
.benefit-text-2 h6 {
	color: grey;
}

.benefit-container-3 {
	padding: 2em;
	background-color: var(--testimonial-card-bg);
}
.benefit-container-overlay-3 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 80%;
	margin: auto;	
}
.benefit-image-3 {
	width: 50%;
}
.benefit-image-3-mobile {
	display: none;
}
.benefit-text-3 {
	display: flex;
	flex-direction: column;
	margin: auto;
	justify-content: center;	
}


@media only screen and (max-width: 1068px) {
	.hero-bottom {
		display: flex;
/*		flex-direction: column-reverse;*/
		width: 100%;
		align-items: center;		
	}
	.hero-image {
		width: 65%;
		margin-right: 10px;
/*		margin-top: 20px;*/
	}
	.hero-image-2 {
		width: 65%;
		margin-right: 10px;
		margin-top: 20px;
	}		
	.hero-what-is-osoji {
		width: 35%;
	}

	.how-it-works-container {
		padding: 1em;
		width: 95%;
		margin: auto;
	}	
	.how-it-works-bottom{
/*		flex-direction: column;*/
		align-items: flex-start;
		justify-content: center;
	}	
	.how-it-works-group {
		width: 300px;
		margin-left: 5px;
		margin-right: 5px;
	}
	.how-it-works-image {
		max-width: 150px;
		height: 120px;
	}
	.how-it-works-text {
		margin-top: 5px;
		font-size: 1.1em;
		padding: 5px;
	/*	width: 80%;*/
	}

	.benefit-image-2 {
		width: 300px;
		margin-top: 0px;
	}
	.benefit-image-3 {
		width: 500px;
		margin-top: 0px;
	}			

}

@media only screen and (max-width: 767px) {
	.hero-container {
		margin: 1em;
	}
	.hero-bottom {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 20px;
	}
	.hero-image {
		width: 100%;
		min-height: 300px;
		object-fit: cover;
		margin: 0px;
		margin-top: 10px;
	}
	.hero-image-2 {
		width: 100%;
		margin-top: 0px;
		margin-right: 0px;		
	}	
	.hero-what-is-osoji {
		width: 300px;
		margin-top: 20px;
		font-size: 1em;
	}
	.hero-what-is-osoji ul {
		padding-left: 0px;
		margin: auto;
		margin-bottom: 20px;
	}			
	.hero-header {
		font-size: 36px;
		width: 80%;
		margin: auto;
		margin-bottom: 10px;
		font-weight: bold;
	}	
	.hero-subtitle {
		font-size: 18px;
		max-width: 80%;
		margin: auto;
		margin-bottom: 20px;
	}	
	.hero-subtitle-2 {
		text-align: center;
		font-size: 18px;
		margin: auto;
		margin-bottom: 16px;
		max-width: 280px;
	}		

	.landing-section-header {
		font-size: 40px;
		font-weight: 700;
		line-height: 54.56px;
	}

	.how-it-works-container {
		padding: 1em;
		width: 100%;
	}
	.benefit-container-1 {
		height: 400px;
	}

	.how-it-works-bottom{
		flex-direction: column;
		align-items: center;
	}
	.benefit-container-overlay-2 {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
	.benefit-image-2 {
		display: none;
	}
	.benefit-image-2-mobile {
		display: block;
		width: 300px;
		margin-top: -10px;
		margin-bottom: 20px;
	}	
	.benefit-container-overlay-3 {
		display: flex;
		flex-direction: column;
	}
	.benefit-image-3 {
		display: none;
	}
	.benefit-image-3-mobile {
		display: block;
		width: 300px;
		margin-top: -10px;
		margin-bottom: 20px;		
	}		
}


