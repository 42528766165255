
.usecase-container {
	padding: 2em;
/*	background-color: var(--bg2);*/
	background-color: var(--usecase-section-bg);
}
.usecase-list-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 40px;
}
.usecase-list {
/*	width: 240px;*/
	margin: auto;
/*	margin-left: 15px;
	margin-right: 15px;*/
	margin-top: 20px;
}
.usecase-list h5 {
/*	color: var(--primary);*/
	text-align: left;
	font-size: 1.3em;
	font-weight: 600;
	margin-bottom: 20px;
}
.usecase-card-group {
	display: flex;
	flex-direction: row;
	width: 850px;	
	justify-content: space-between;	
}

.usecase-card {
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(128,128,128, 0.5);
	border-radius: 10px;
	box-shadow:  3px 3px 1px rgba(0, 0, 0, 0.1);
	width: 260px;
	height: 150px;
	margin: auto;
	margin-bottom: 20px;
	padding: 10px;
/*	color: var(--secondary);*/
	justify-content: flex-start;
	align-items: center;
	background-color: var(--usecase-card-bg);	
/*	background-color: rgba(247, 243, 240, 0.6);*/
}
.usecase-card p {
	color: var(--header);
}

.usecase-card:hover {
	cursor: pointer;
	border: 1px solid var(--main);
}
.usecase-card-image {
	margin: 10px;
/*	height: 60%;*/
}

.landing-typing{
	font-size: 1.5em;
	color: var(--header);
}

@media only screen and (max-width: 1068px) {
	.usecase-list h5 {
		text-align: center;
	}
	.usecase-card-group {
/*		flex-direction: column;
		width: auto;*/
		width: 700px;
	}
	.usecase-card {
		width: 220px;
	}	
}

@media only screen and (max-width: 767px) {
	.usecase-card-mobile-hide {
/*		display: none;*/
	}
	.usecase-card-group {
		flex-direction: column;
		width: auto;
	}
	.usecase-list h5 {
		text-align: center;
	}
	.usecase-card {
		width: 220px;
	}		
}