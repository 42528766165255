.mg-glass-icon {
    background-color: transparent;
    fill: darkgrey;
    width: 35px;
    padding: 0 6px;
    position: absolute;
    top: 13px;
    left: 0.5%;
    z-index: 200;
    vertical-align: middle;
    cursor: pointer;
}

