.plus-button {
	margin-left: 5px;
	margin-bottom: 3px;
	padding: 1px 1px 1px 1px;
	background-color: var(--main);
/*	background-color: transparent;*/
	border-color: transparent;
	outline: none;
	width: 30px;
	border-radius: 100%
}
.plus-button:focus {
	outline: none;
}
.plus-button:hover {
	background-color: #f0b607;
}