.sidebar-container {
    background-color: var(--form-bg);  
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 250px;
    height: 100%;
    transition: 0.5s;
    padding-top: 25px;
    padding-bottom: 10px;
    border-right: 1px solid var(--search-border);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: auto;

}
/* scroll bar for this container only */
.sidebar-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.sidebar-container::-webkit-scrollbar-track {
    background: var(--form-bg);
}
.sidebar-container:hover::-webkit-scrollbar-thumb {
    background: var(--main);
    border-radius: 10px;  

/*  border-left: 1px var(--form-bg) solid;  IF want to have some padding left/right of scrollbar
  border-right: 1px var(--form-bg) solid;
  background-clip: padding-box;*/
}

.sidebar-desk-group {
    padding-left: 16px;
    color: var(--header) !important;
    margin-top: 10px; 
    margin-bottom: 10px;
}
.sidebar-desk-icon {
    width: 20px;
    margin-right: 10px; 
    margin-top: -3px;    
}

.sidebar-toggle-icon-button {
    display: flex;
    border: none;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;

    background: none;

    border-radius: 20px;
    outline: none;

}
.sidebar-toggle-icon-button:hover {
    background-color: var(--hover-bg);  
}
.sidebar-toggle-icon-button:focus {
    outline: none;    
}

.sidebar-collapsed {
	width: 40px;
    align-items: center;
    overflow: visible;
}

.sidebar-head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  margin-bottom: 20px;
}


.sidebar-menu-item {
  color: var(--header); 
  padding: 5px 16px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  align-items: center;
  width: 100%  
}
.sidebar-menu-item:hover {
  background-color: var(--skeleton-bg);  
}
/*.sidebar-menu-item-with-dropdown {
  padding: 5px 16px;
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  align-items: center;  
}
.sidebar-menu-item-with-dropdown:hover {
  background-color: var(--skeleton-bg);  
}*/
  
.sidebar-section-header {
	color: var(--header);
	cursor: pointer;
	padding-left: 16px;	
  width: 100%;

}

.sidebar-dropdown-item { 
	color: var(--header);	
  padding: 5px 16px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;	
  align-items: center;
  width: 100%
}
.sidebar-dropdown-item:hover {
	background-color: var(--skeleton-bg);
}
.sidebar-dropdown-item-text {
  padding-left: 12px; /*used to be 16*/
  color: var(--header);
/*  font-style: italic;*/
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; 
  overflow: hidden;

/*  width: 90%;*/
  margin-right: 8px;

}
.sidebar-dropdown-item-count {
  font-size: 0.9em;
  color: grey;
}

/* ROOMS  */
.rooms-sidebar-section {
	text-align: left;
	margin-top: 10px;
	margin-bottom: 10px;
  width: 100%
}

.rooms-sidebar-header {
  color: var(--header);
  cursor: pointer;
  padding-left: 16px; 
  padding-right: 6px; 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
}


.sidebar-room-preview-image {
  object-fit: cover;
  border-radius: 50%;
  width: 28px;
  min-width: 28px; /*somehow need this*/
  height: 28px;
}

.rooms-sidebar-header h6 {
  width: 80%;
}
.rooms-sidebar-header h6:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

/*.sidebar-create-room {
	padding-left: 16px;
	padding-right: 10px;
	color: var(--header)
}*/
.sidebar-create-room-input {
	padding-left: 16px;
/*	padding-right: 10px;*/
  padding-right: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
}
.sidebar-create-room-input input {
	width: 80%;
	padding: 3px 12px;
	background-color: var(--form-bg);
	color: var(--form-text);
	border: 1px solid var(--greyborder);	
}
.sidebar-create-room-input input:focus {
	border: 1px solid var(--main);		
	outline: none;
}


.news-red-dot-sidebar {
    width: 7px;
    height: 7px;
    margin-bottom: 8px;
    margin-left: 3px;
}

.sidebar-user-group {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 0px;

  padding: 8px 16px;
  width: 100%;

/*  margin-right: -20px;*/
}
.sidebar-user-group:hover {
  background-color: var(--skeleton-bg);
}
.sidebar-user-group h6 {
  margin-bottom: 0;
  color: var(--header);
}
.sidebar-user-icon {
  padding: 0px;
  margin-right: 10px;
  width: 20px;
  margin-top: -3px;
}
.sidebar-user-dropdown-menu {
  background-color: var(--navbar-dropdown-bg);
  color: var(--navbar-quicklink-hover);
}
.sidebar-user-dropdown-item {
  background-color: var(--navbar-dropdown-bg);
  color: var(--navbar-quicklink-hover); 
}


/*collapsed state*/
.rooms-dropdown-at-collapsed {
  background-color: var(--form-bg);
  max-height: 60vh;
  overflow-y: auto;
  margin-left: 30px;
  margin-top: -35px;

}

@media only screen and (max-width: 760px) {  /* for mobile except ipad*/

}

