
.groupchangeroom-container {
/*	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;	*/
	width: 100%;
}

.groupchangeroom-room-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 16px;
	max-height: 500px;
	overflow: auto;
}
/* scroll bar for this container only */
.groupchangeroom-room-group::-webkit-scrollbar {
	width: 5px;
	height: 10px;
}
.groupchangeroom-room-group::-webkit-scrollbar-track {
  background: var(--bg);
}

.groupchangeroom-room-group:hover::-webkit-scrollbar-thumb {
	background: var(--main);
	border-radius: 10px;	
}

.groupchangeroom-room-card {
	background-color: white;
	color: black;
	border-radius: 8px;
	border: 1px solid var(--greyborder);
	width: 200px;
	height: 60px;
	padding: 6px 12px;
	margin: auto;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;	
	line-height: 16px;
} 
.groupchangeroom-room-card:hover {
	background-color: var(--greyborder);
}


@media (max-width: 991px) {
	.groupchangeroom-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;		
		width: 100%;
		margin-top: 20px;		
	}

	.groupchangeroom-room-group {
		max-height: 500px;
		width: 320px;
		margin: auto;
		text-align: center;		
	}
	.groupchangeroom-room-card {
		width: 60%;
		text-align: center;
		align-items: center;				
	}
}

@media (max-width: 600px) {
	.groupchangeroom-room-group {
		max-height: 300px;
	}
}